import ObjectUtils from "@/utils/object-utils";
import parseISO from "date-fns/parseISO";
import {createMaxRule, requiredOnChangeRule, requiredRule, typeNumberRule, typeTelno} from "@/utils/validation-rules";
import {SpecShareStatus} from "@/entities/specs/spec-share-entity";

export interface ISpecShareMessageRequest {
  operatorName: string;
  department: string|null;
  comment: string|null;
}

export default class SpecShareMessage implements ISpecShareMessageRequest {
  public status!:SpecShareStatus;
  public operatorName!: string;
  public department: string|null = null;
  public comment: string|null = null;
  public createdAt!: Date;

  constructor(init:Partial<SpecShareMessage> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.createdAt) {
      this.createdAt = parseISO((init as any).createdAt);
    }
  }
}

export const ValidatorRules:any = {
  operatorName: [requiredRule, createMaxRule(255, 'char')],
  department: [createMaxRule(255, 'char')],
  comment: [createMaxRule(1024, 'char')],
};
