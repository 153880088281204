import ObjectUtils from "@/utils/object-utils";
import {createMaxRule, createMaxStringRule} from "@/utils/validation-rules";
import {SpecShareEntity} from "@/entities/specs/spec-share-entity";
import SpecEntity from "@/entities/specs/spec-entity";
import {formatDate} from "@/utils/date-utils";
import SpecAcceptanceIntraCustomValues from "@/entities/specs/spec-acceptance-intra-custom-values";
import CompanyDepartmentEntity, {getDepartmentNames} from "@/entities/company-department-entity";
import {i18n} from "@/bootstraps/locale";
import parseISO from "date-fns/parseISO";
import {SpecForwardingEntity} from "@/entities/specs/spec-forwarding-entity";
import {ApprovalRequestEntity, IHasApprovalRequest} from "@/entities/approval-request-entity";

export class SpecAcceptanceEntity {
  public id!:number;
  public receiverId!:number;
  public receiverIntraCode:string|null = null;
  public receiverMemo:string|null = null;
  public specForwarding!: SpecForwardingEntity | null;

  public specShare:SpecShareEntity|null = null;
  public tags:string[] = [];

  public departments: CompanyDepartmentEntity[] = [];

  public operatorId: number|null = null;
  public operator: { id:number; email:string; name:string; nameKana:string; }|null = null;

  public intraCustomValues:SpecAcceptanceIntraCustomValues[] = [];

  public createdAt!: Date;

  constructor(init:SpecAcceptanceEntity) {
    ObjectUtils.assignLiteralFields(this, init);

    if(init.specShare) {
      this.specShare = new SpecShareEntity(init.specShare);
    }
    if (init.intraCustomValues) {
      this.intraCustomValues = init.intraCustomValues.map(d => new SpecAcceptanceIntraCustomValues(d));
    }
    if (init.operator) {
      this.operator = init.operator;
    }
    this.departments = init.departments || [];

    this.tags = init.tags || [];

    this.createdAt = parseISO(init.createdAt as any);

    this.specForwarding = init.specForwarding || null;
  }

  public getDepartmentNames(withManagementTag = true): string[] {
    return getDepartmentNames(withManagementTag, this.departments);
  }
}

export class SpecAcceptanceSpecEntity implements IHasApprovalRequest {
  public id!:number;
  public specAcceptanceId!:number;
  public specId!:number;
  public isAccepted!:boolean;

  public spec!:SpecEntity;
  public approvalRequest:ApprovalRequestEntity | null = null;

  public selfSubmissionId!: number | null;

  constructor(init:SpecAcceptanceSpecEntity) {
    ObjectUtils.assignLiteralFields(this, init);
    if (init.spec) {
      this.spec = new SpecEntity(init.spec);
    }
    if (init.approvalRequest) {
      this.approvalRequest = new ApprovalRequestEntity(init.approvalRequest);
    }
  }

  public getSelectionLabel(format = 'yyyy/MM/dd'): string {
    return formatDate(this.spec.createdAt, format) + ' ' + i18n.t('配信分') + (this.isAccepted ? '' : `（${i18n.t('未受領')}）`);
  }

  public get isSelfSubmission(): boolean {
    return !!this.selfSubmissionId;
  }
}
export class SpecAcceptanceSpecDetailEntity extends SpecAcceptanceSpecEntity {
  public clientId!: number | null;
}

export const ValidatorRules = {
  receiverIntraCode: [createMaxStringRule],
  receiverMemo: [createMaxStringRule()],
}
